/*global $,console */
(function () {
    'use strict';
 
    var get_number = $(window).width() < 1054 ? 1 : 3;
    var get_arrow = $(window).width() > 1054 ? true : false;
    var get_dots = $(window).width() < 1054 ? true : false;
    var get_gallery = $(window).width() < 990 ? 1 : 3;
    
    //
    $('#home-slider div:first-child').show().addClass('active');
    
    //
    $('#home-slider').css('height', window.innerHeight);
    $('#home-slider div').css('height', window.innerHeight);
    $(window).resize(function() {
        $('#home-slider').css('height', window.innerHeight);
        $('#home-slider div').css('height', window.innerHeight);
    });
    
    //
    $('#home-slider-wrapper .next').on('click', function(e){
        
        //
        var currentItem = $('#home-slider div.active'),
            nextItem = currentItem.next('div');
        
        //
        if(nextItem.length<1){
            nextItem = $('#home-slider div:first-child');
        }
       
        //
        currentItem.fadeOut(500, function(e){
            $(this).removeClass('active');
            nextItem.fadeIn(500).addClass('active');
        });
        
    });
    
    //
    $('#home-slider-wrapper .prev').on('click', function(e){
        
        //
        var currentItem = $('#home-slider div.active'),
            nextItem = currentItem.prev('div');
        
        //
        if(nextItem.length<1){
            nextItem = $('#home-slider div:last-child');
        }
        
        //
        currentItem.fadeOut(250, function(e){
            $(this).removeClass('active');
            nextItem.fadeIn(250).addClass('active');
        });
        
    });
    
    //
    var bannerInterval = setInterval(function(){
        $('#home-slider-wrapper .next').click();
    },10000);
    
    //
    $('#home-slider-boxes').slick({
        infinite: true,
        dots: get_dots,
        arrows: get_arrow,
        slidesToShow: get_number,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 800
    });
    
    /*$('#home-slider').slick({
        infinite: true,
        speed: 500,
        autoplaySpeed: 2000,
        autoplay: true,
        fade: true,
        arrows: false,
        dots: false,
        cssEase: 'linear'
    });*/
    
    $('.news-ticker').slick({
        infinite: true,
        speed: 500,
        autoplaySpeed: 5000,
        autoplay: true,
        fade: true,
        arrows: true,
        dots: false,
        cssEase: 'linear'
    });
    
    $('.teacher-info-slider').slick({
        infinite: true,
        dots: false,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 800
    });
    
    $('#gallery-slider').slick({
        centerMode: true,
        slidesToShow: get_gallery,
        autoplay: true,
        speed: 800,
        responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 1
          }
        }
        ]
    });
    
    /*
    * Back to top button
    */
    $('.back-to-top').click(function(){
        $('html, body').animate({scrollTop:'0'},1000);
    });
    
    /*
    * Desktop menu actions
    */
    $('#navigation .menu-item-has-children').hover(function(){
        $('#navigation .sub-menu').stop().hide();
        $(this).find('.sub-menu').stop().show();
    },function(){
        $('#navigation .sub-menu').stop().hide();
    });
    
    /*$('#navigation .sub-menu').hover(function(){
        $(this).prev('a').stop().css({'background': $('.news-ticker').css('background-color'),'color':'#fff'});
    },function(){
        $(this).prev('a').stop().css({'background':'none','color':'#000'});
    });*/
    
    $('#navigation .sub-menu a.group').on('click', function(e){
        e.preventDefault();
    })
    
    /*
    * Mobile menu actions
    */
    $('.mobile-menu').click(function(){
        $(this).toggleClass('active');
        if( $(this).hasClass('active') ){
            $('#mobile-navigation').css({'left':0});
        }else{
            $('#mobile-navigation').css({'left':'-100%'});
        }
    });

    /*
    *
    */
    var jqxhr = null;
    
    // News
    $('.view-more-news').on('click', function(e){
        e.preventDefault();
        var target = this;
        (function() {
            return new Promise((resolve, reject) => {
                
                //
                if (jqxhr !== null) {
                    jqxhr.abort();
                }
                
                //
                jqxhr = $.ajax({
                    url: $(this).attr('href'),
                    method: "GET",
                    data: {
                        from: $(".news-box").length
                    }
                }).done(function (data) {
                    resolve(data);
                }).fail(function (e) {
                    reject(e);
                }).always(function (e) {
                    jqxhr = null;
                });
                
            });
        }).bind(this)().then(function(data){
            $('.latest-news-boxes').append(data);
            if($(".news-box").length >= $('.latest-news-boxes').attr('data-max')){
                $('.view-more-news').hide();
            }
        });
    });
    
    // Events
    $('.view-more-events').on('click', function(e){
        e.preventDefault();
        var target = this;
        (function() {
            return new Promise((resolve, reject) => {
                
                //
                if (jqxhr !== null) {
                    jqxhr.abort();
                }
                
                //
                jqxhr = $.ajax({
                    url: $(this).attr('href'),
                    method: "GET",
                    data: {
                        from: $(".event-box").length
                    }
                }).done(function (data) {
                    resolve(data);
                }).fail(function (e) {
                    reject(e);
                }).always(function (e) {
                    jqxhr = null;
                });
                
            });
        }).bind(this)().then(function(data){
            $('.events-boxes').append(data);
            if($(".event-box").length >= $('.events-boxes').attr('data-max')){
                $('.view-more-events').hide();
            }
        });
    });
    
    // Galleries
    $('.view-more-galleries').on('click', function(e){
        e.preventDefault();
        var target = this;
        (function() {
            return new Promise((resolve, reject) => {
                
                //
                if (jqxhr !== null) {
                    jqxhr.abort();
                }
                
                //
                jqxhr = $.ajax({
                    url: $(this).attr('href'),
                    method: "GET",
                    data: {
                        from: $(".gallery-entry").length
                    }
                }).done(function (data) {
                    resolve(data);
                }).fail(function (e) {
                    reject(e);
                }).always(function (e) {
                    jqxhr = null;
                });
                
            });
        }).bind(this)().then(function(data){
            $('.gallery-item').append(data);
            if($(".gallery-entry").length >= $('.gallery-item').attr('data-max')){
                $('.view-more-galleries').hide();
            }
        });
    });
    
    // Resources
    $('.view-more-staff').on('click', function(e){
        e.preventDefault();
        var target = this;
        (function() {
            return new Promise((resolve, reject) => {
                
                //
                if (jqxhr !== null) {
                    jqxhr.abort();
                }
                
                //
                jqxhr = $.ajax({
                    url: $(this).attr('href'),
                    method: "GET",
                    data: {
                        from: $(".staff-box").length
                    }
                }).done(function (data) {
                    resolve(data);
                }).fail(function (e) {
                    reject(e);
                }).always(function (e) {
                    jqxhr = null;
                });
                
            });
        }).bind(this)().then(function(data){
            $('.staff-boxes').append(data);
            if($(".staff-box").length >= $('.staff-boxes').attr('data-max')){
                $('.view-more-staff').hide();
            }
        });
    });
    
    // Resources
    $('.page-section').on('click', '.calendar-prev, .calendar-next', function(e){
        e.preventDefault();
        var target = this;
        (function() {
            return new Promise((resolve, reject) => {
                
                //
                if (jqxhr !== null) {
                    jqxhr.abort();
                }
                
                //
                jqxhr = $.ajax({
                    url: '/events/month',
                    method: "GET",
                    data: {
                        date: $(this).attr('data-date'),
                    }
                }).done(function (data) {
                    resolve(data);
                }).fail(function (e) {
                    reject(e);
                }).always(function (e) {
                    jqxhr = null;
                });
                
            });
        }).bind(this)().then(function(data){
            $('#calendar-wrapper').html(data);
            
        });
    });
    
}());